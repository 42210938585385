import { logger } from '@invisible/logger/server'
import { createSlice, Middleware, nanoid, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { cloneDeep, isNil } from 'lodash/fp'
import { DropResult } from 'react-beautiful-dnd'

import { IGenericSavedData } from '../models/Menu'
import { TDirection } from '../types'
import { RootState } from './store'

interface IState {
  columns: { key: string; name: string }[]
  rows: Record<string, string>[]
  currentCol: number
  currentRow: number
  savedData: IGenericSavedData
}

const safeJSONParse = (a: string) => {
  try {
    return JSON.parse(a)
  } catch {
    return undefined
  }
}

export const initialState: IState = {
  columns: [
    { key: 'id-1', name: 'Column 1' },
    { key: 'id-2', name: 'Column 2' },
    { key: 'id-3', name: 'Column 3' },
  ],
  rows: [{ 'id-1': '', 'id-2': '', 'id-3': '' }],
  currentCol: 0,
  currentRow: 0,
  savedData: {} as IGenericSavedData,
}

const genericModeSlice = createSlice({
  name: 'genericModeSlice',
  initialState,
  reducers: {
    addColumn: {
      reducer: (state, action: PayloadAction<{ id: string }>) => {
        state.columns.push({
          key: action.payload.id,
          name: 'Column',
        })
      },
      prepare: () => {
        const id = nanoid()
        return { payload: { id } }
      },
    },

    editColumnText: (state, action: PayloadAction<{ columnIndex: number; value: string }>) => {
      const { columnIndex, value } = action.payload

      state.columns[columnIndex].name = value
    },

    deleteColumn: (state, action: PayloadAction<{ columnIndex: number }>) => {
      state.columns.splice(action.payload.columnIndex, 1)
    },

    editRowText: (
      state,
      action: PayloadAction<{
        rowIndex: number
        cellId: string
        value: string
        mode?: 'overwrite' | 'append'
      }>
    ) => {
      const { rowIndex, cellId, value, mode } = action.payload

      if (isNil(state.rows?.[rowIndex])) return

      mode === 'append' && state.rows[rowIndex][cellId]
        ? (state.rows[rowIndex][cellId] += ` ${value}`)
        : (state.rows[rowIndex][cellId] = value)
    },

    addRow: (state, action: PayloadAction<{ rowIndex: number }>) => {
      const newRow: Record<string, string> = {}
      state.columns.map((column) => (newRow[column.key] = ''))
      state.rows.splice(action.payload.rowIndex + 1, 0, newRow)
    },

    deleteRow: (state, action: PayloadAction<{ rowIndex: number }>) => {
      state.rows.splice(action.payload.rowIndex, 1)
    },

    makeCellActive: (
      state,
      action: PayloadAction<{
        rowIndex: number
        cellIndex: number
      }>
    ) => {
      const { rowIndex, cellIndex } = action.payload

      state.currentRow = rowIndex
      state.currentCol = cellIndex
    },

    handleOnDragEnd: (state, action: PayloadAction<DropResult>) => {
      const { source, destination } = action.payload
      if (!destination) return

      const [reorderedItem] = state.rows.splice(source.index, 1)
      state.rows.splice(destination.index, 0, reorderedItem)

      return
    },

    navigate: (state, action: PayloadAction<TDirection>) => {
      const isTop = state.currentRow === 0
      const isBottom = state.currentRow === state.rows.length - 1
      const isLastColumn = state.currentCol === state.columns.length - 1

      if (action.payload === 'up') {
        if (isTop) return
        // Reduce currentRow only when greater than 0-
        state.currentRow = Math.max(0, state.currentRow - 1)
        return
      }

      if (action.payload === 'left') {
        if (state.currentCol > 0) {
          state.currentCol = state.currentCol - 1
          return
        }
        //If currentCol is on the first column, try to navigate up
        if (isTop) return
        genericModeSlice.caseReducers.navigate(state, {
          ...action,
          payload: 'up',
        })
        state.currentCol = state.columns.length - 1
        return
      }

      if (action.payload === 'right') {
        if (!isLastColumn) {
          state.currentCol += 1
          return
        }

        if (isLastColumn && !isBottom) {
          state.currentCol = 0
          state.currentRow += 1
          return
        }
      }

      if (action.payload === 'down') {
        if (!isBottom) {
          state.currentRow += 1
          return
        }
      }
    },

    reset: () => initialState,

    setColumns: (state, action: PayloadAction<string>) => {
      const parsedColumns = safeJSONParse(action.payload)

      if (Array.isArray(parsedColumns)) {
        const updatedColumns = parsedColumns.map((column) => ({
          key: nanoid(),
          name: column,
        }))
        state.columns = updatedColumns
      }
    },

    setData: (
      state,
      action: PayloadAction<{
        columns: IState['columns']
        rows: IState['rows']
      }>
    ) => {
      const { columns, rows } = action.payload
      state.columns = cloneDeep(columns)
      state.rows = cloneDeep(rows)
    },
  },
})

export const saveToFirebaseMiddleware: Middleware<Record<string, unknown>, RootState> =
  (store) => (next) => async (action) => {
    const { type } = action

    if (
      type === navigate.toString() ||
      type === makeCellActive.toString() ||
      type === setColumns.toString() ||
      type.split('/')[0] !== 'genericModeSlice'
    ) {
      return next(action)
    }

    const result = next(action)

    const menu = store.getState().ocrState.activeMenu

    if (menu?.hash) {
      try {
        await axios.post(`/api/save-menu?id=${menu.hash}`, {
          menu: {
            ...menu,
            data: {
              columns: store.getState().genericState.present.columns,
              rows: store.getState().genericState.present.rows,
            },
            version: 3,
          },
        })
      } catch (error) {
        logger.error(`Error making axios call to save-menu`, error)
      }
    }

    return result
  }

export const {
  addColumn,
  addRow,
  deleteColumn,
  deleteRow,
  editColumnText,
  editRowText,
  makeCellActive,
  handleOnDragEnd,
  navigate,
  reset,
  setColumns,
  setData,
} = genericModeSlice.actions

export default genericModeSlice.reducer
