/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { logger } from '@invisible/logger/server'
import { createDraftSafeSelector, createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { isUndefined } from 'lodash'
import { cloneDeep, trim } from 'lodash/fp'
import { DropResult } from 'react-beautiful-dnd'
import { sentenceCase } from 'sentence-case'
import { titleCase } from 'title-case'
import { v4 as uuid } from 'uuid'

import { IMenu } from '../models/Menu'
import {
  TAddRow,
  TCategory,
  TDeleteRow,
  TExtra,
  TFlattenedData,
  TMenuState,
  TNavigate,
  TRow,
  TUpdateRow,
} from '../types'
import { RootState } from './store'

const normalizePrice = (t: string) => trim(t.replace(/\D/g, ''))

const selectPresent = (state: RootState) => state.menuState.present

// Memoized draft-safe selector
export const flattenedDataSelector = createDraftSafeSelector(selectPresent, (state) =>
  (state.categories ?? []).reduce<TFlattenedData>((acc, curr, categoryIndex) => {
    acc.push({ id: curr.id, type: 'category', categoryIndex })
    curr.items.map((item, itemIndex) => {
      acc.push({
        id: item.id,
        type: 'item',
        parentId: curr.id,
        categoryIndex,
        itemIndex,
      })
      item.extras.map((extraId) => {
        acc.push({
          id: extraId,
          type: 'extra',
          parentId: item.id,
          extraId,
          categoryId: curr.id,
        })
        const extra = state.extras.find((e) => e.id === extraId)
        extra?.options.map((option, optionIndex) =>
          acc.push({
            id: option.id,
            type: 'option',
            parentId: extraId,
            optionIndex,
            extraId,
            itemId: item.id,
          })
        )
      })
    })
    return acc
  }, [])
)

const extraIsUsedElsewhere = (flattenedData: TFlattenedData, extraId: string) =>
  flattenedData.filter((e) => e.id === extraId).length > 0

export const initialState: TMenuState = {
  categories: [{ id: '0', name: '', description: '', items: [] }],
  extras: [],
  currentCol: 0,
  currentRow: 0,
  menuData: {} as IMenu,
}

const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    addRow: {
      reducer: (state, action: PayloadAction<TAddRow>) => {
        const { type, id, categoryId, itemId, extraId } = action.payload

        if (type === 'category') {
          state.categories.push({ id, name: '', description: '', items: [] })
        } else if (type === 'item') {
          const categoryIndex = state.categories.findIndex((e) => e.id === categoryId)
          state.categories[categoryIndex].items.push({
            id,
            name: '',
            price: '',
            description: '',
            extras: [],
          })
        } else if (type === 'extra') {
          const categoryIndex = state.categories.findIndex((e) => e.id === categoryId)
          const itemIndex = state.categories[categoryIndex].items.findIndex((e) => e.id === itemId)
          state.extras.push({
            id,
            name: '',
            min: '',
            max: '',
            free: '',
            options: [],
          })
          state.categories[categoryIndex].items[itemIndex].extras.push(id)
        } else if (type === 'option') {
          const extrasIndex = state.extras.findIndex((e) => e.id === extraId)
          state.extras[extrasIndex].options.push({ id, name: '', price: '' })
        }

        const flattenedData: TFlattenedData = flattenedDataSelector({
          //@ts-ignore
          menuState: {
            present: state,
          },
        })
        const newCurrentRow = flattenedData.findIndex((a) => a.id === id)

        state.currentRow = newCurrentRow
        state.currentCol = 0
      },
      prepare: ({ type, categoryId, itemId, extraId }: Omit<TAddRow, 'id'>) => {
        const id = uuid()
        return { payload: { id, type, categoryId, itemId, extraId } }
      },
    },

    deleteRow: (state, action: PayloadAction<TDeleteRow>) => {
      const { type, categoryIndex, itemIndex, extraId, extraIndex, optionIndex } = action.payload

      // When the row to be deleted is the last row, move the selected cell a row higher
      if (
        flattenedDataSelector({
          //@ts-ignore
          menuState: {
            present: state,
          },
        }).length -
          1 ===
        state.currentRow
      ) {
        state.currentRow = Math.max(0, state.currentRow - 1)
      }

      if (type === 'category') {
        state.categories.splice(categoryIndex!, 1)
        return
      }

      if (type === 'item') {
        const itemExtras = [...state.categories[categoryIndex!].items[itemIndex!].extras]
        state.categories[categoryIndex!].items.splice(itemIndex!, 1)

        // // Go through all item extras and delete extras that only appear in this item
        itemExtras.map((eId) => {
          if (
            !extraIsUsedElsewhere(
              flattenedDataSelector({
                menuState: { present: state },
              } as RootState),
              eId
            )
          ) {
            const sharedExtraIndex = state.extras.findIndex((e) => e.id === eId)
            state.extras.splice(sharedExtraIndex, 1)
          }
        })
        return
      }

      if (type === 'extra') {
        state.categories[categoryIndex!].items[itemIndex!].extras.splice(extraIndex!, 1)
        if (
          !extraIsUsedElsewhere(
            flattenedDataSelector({
              menuState: { present: state },
            } as RootState),
            extraId!
          )
        ) {
          const sharedExtraIndex = state.extras.findIndex((e) => e.id === extraId)
          state.extras.splice(sharedExtraIndex, 1)
        }
        return
      }

      if (type === 'option') {
        const index = state.extras.findIndex((e) => e.id === extraId)
        state.extras[index].options.splice(optionIndex!, 1)
        return
      }
    },

    updateRow: (state, action: PayloadAction<TUpdateRow>) => {
      const {
        field,
        value,
        type,
        categoryIndex,
        itemIndex,
        extraId,
        optionIndex,
        mode = 'overwrite',
      } = action.payload

      if (type === 'category') {
        if (field !== 'name' && field !== 'description') return

        mode === 'overwrite'
          ? (state.categories[categoryIndex!][field] = value as string)
          : (state.categories[categoryIndex!][field] += ` ${value}` as string)
      }

      if (type === 'item') {
        if (field !== 'name' && field !== 'description' && field !== 'price') return

        const item = state.categories[categoryIndex!].items[itemIndex!]
        if (mode === 'overwrite') {
          item[field] = field === 'price' ? normalizePrice(value) : value
        }

        if (mode === 'append') {
          item[field] += ` ${value}`
        }
        if (mode === 'appendWithoutSpace') {
          item[field] += value
        }
      }

      if (type === 'extra') {
        if (field !== 'min' && field !== 'max' && field !== 'free' && field !== 'name') return

        const extraIndex = state.extras.findIndex((e) => e.id === extraId)
        mode === 'overwrite'
          ? (state.extras[extraIndex][field] = value)
          : mode === 'append'
          ? (state.extras[extraIndex!][field] += ` ${value}`)
          : (state.extras[extraIndex!][field] += value)
      }

      if (type === 'option') {
        if (field !== 'price' && field !== 'name') return
        const extraIndex = state.extras.findIndex((e) => e.id === extraId)

        const option = state.extras[extraIndex!].options[optionIndex!]

        if (mode === 'overwrite') {
          option[field] = field === 'price' ? normalizePrice(value) : value
        }
        if (mode === 'append') {
          option[field] += ` ${value}`
        }
        if (mode === 'appendWithoutSpace') {
          option[field] += value
        }
      }
    },

    resetMenu: (state) => ({ ...initialState, menuData: state.menuData }),

    //Will be refactored
    navigate: (state, action: PayloadAction<TNavigate>) => {
      const { direction } = action.payload

      const flattenedData: TFlattenedData = flattenedDataSelector({
        //@ts-ignore
        menuState: {
          present: state,
        },
      })
      const isBottom = state.currentRow === flattenedData.length - 1
      const isTop = state.currentRow === 0

      const precedingRowType = flattenedData[state.currentRow - 1]?.type
      if (direction === 'up') {
        if (isTop) return
        if (flattenedData[state.currentRow - 1]?.type === 'category') {
          state.currentCol = Math.min(state.currentCol, 1)
        }
        if (flattenedData[state.currentRow]?.type === 'extra') {
          state.currentCol = Math.min(state.currentCol, 2)
        }
        state.currentRow = Math.max(0, state.currentRow - 1)
      } else if (direction === 'left') {
        if (state.currentCol > 0) {
          state.currentCol = state.currentCol - 1
        } else {
          menuSlice.caseReducers.navigate(state, {
            ...action,
            payload: { direction: 'up' },
          })
          if (!(isTop && state.currentCol === 0))
            precedingRowType === 'category'
              ? (state.currentCol = 1)
              : precedingRowType === 'item'
              ? (state.currentCol = 2)
              : precedingRowType === 'extra'
              ? (state.currentCol = 3)
              : (state.currentCol = 1)
        }
      } else if (direction === 'right') {
        if (
          (flattenedData[state.currentRow]?.type === 'category' && state.currentCol === 1) ||
          (flattenedData[state.currentRow]?.type === 'item' && state.currentCol === 2) ||
          (flattenedData[state.currentRow]?.type === 'extra' && state.currentCol === 3) ||
          (flattenedData[state.currentRow]?.type === 'option' && state.currentCol === 1)
        ) {
          if (!isBottom) {
            state.currentCol = 0
            state.currentRow = state.currentRow + 1
          }
        } else {
          state.currentCol = state.currentCol + 1
        }
      } else {
        if (flattenedData[state.currentRow]?.type === 'extra') {
          state.currentCol = Math.min(state.currentCol, 2)
        }
        if (!isBottom) {
          state.currentRow = state.currentRow + 1
        }
      }
    },

    fixCase: (state) => {
      state.categories.forEach((category) => {
        category.name = titleCase(category.name.toLowerCase().trim())
        category.description = sentenceCase(category.description.toLowerCase().trim(), {
          stripRegexp: /$^/, // Strip no characters
        })

        category.items.forEach((item) => {
          item.name = titleCase(item.name.toLowerCase().trim())
          item.description = sentenceCase(item.description.toLowerCase().trim(), {
            stripRegexp: /$^/, // Strip no characters
          })
        })
      })

      state.extras.forEach((extra) => {
        extra.name = titleCase(extra.name.toLowerCase().trim())

        extra.options.forEach((option) => {
          option.name = titleCase(option.name.toLowerCase().trim())
        })
      })
    },

    multiplyAllCells: (state) => {
      state.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (item.price.slice(-2) !== '00' && item.price !== '') {
            item.price += '00'
          }
        })
      })

      state.extras.forEach((extra) => {
        extra.options.forEach((option) => {
          if (option.price.slice(-2) !== '00' && option.price !== '') {
            option.price += '00'
          }
        })
      })
    },

    multiplyCell: (state) => {
      const flattenedData: TFlattenedData = flattenedDataSelector({
        //@ts-ignore
        menuState: {
          present: state,
        },
      })
      const row = flattenedData[state.currentRow]

      if (row.type === 'item' && state.currentCol === 2) {
        const item = state.categories[row.categoryIndex!].items[row.itemIndex!]
        if (item.price.slice(-2) !== '00' && item.price !== '') {
          state.categories[row.categoryIndex!].items[row.itemIndex!].price += '00'
        }
      }

      if (row.type === 'option' && state.currentCol === 1) {
        const extraIndex = state.extras.findIndex((e) => e.id === row.extraId)
        if (
          state.extras[extraIndex].options[row.optionIndex!].price.slice(-2) !== '00' &&
          state.extras[extraIndex].options[row.optionIndex!].price !== ''
        ) {
          state.extras[extraIndex].options[row.optionIndex!].price += '00'
        }
      }
    },

    setMenu: (state, action: PayloadAction<IMenu>) => {
      state.menuData = action.payload
    },

    handleOnDragEnd: (state, action: PayloadAction<DropResult>) => {
      const { source, type, destination } = action.payload
      if (!destination) return

      if (type === 'category') {
        const [reorderedItem] = state.categories.splice(source.index, 1)
        state.categories.splice(destination.index, 0, reorderedItem)

        return
      }

      if (type === 'item') {
        const sourceCategoryIndex = Number(source.droppableId.split(' ')[1])
        const sourceIndex = Number(source.index)
        const destinationIndex = Number(destination.index)
        const destinationCategoryIndex = Number(destination.droppableId.split(' ')[1])

        const [reorderedItem] = state.categories[sourceCategoryIndex].items.splice(sourceIndex, 1)
        state.categories[destinationCategoryIndex].items.splice(destinationIndex, 0, reorderedItem)

        return
      }

      //Extras
      const categoryIndex = Number(type)
      const itemIndex = Number(source.droppableId)
      const sourceIndex = Number(source.index)
      const destinationIndex = Number(destination.index)

      const [reorderedItem] = state.categories[categoryIndex].items[itemIndex].extras.splice(
        sourceIndex,
        1
      )
      state.categories[categoryIndex].items[itemIndex].extras.splice(
        destinationIndex,
        0,
        reorderedItem
      )
    },

    setData: (state, action: PayloadAction<{ categories: TCategory[]; extras: TExtra[] }>) => {
      const { categories, extras } = action.payload
      state.categories = cloneDeep(categories)
      state.extras = cloneDeep(extras)
    },

    makeRowActive: (state, action: PayloadAction<{ columnIndex: number; rowIndex: number }>) => {
      const { columnIndex, rowIndex } = action.payload
      state.currentCol = columnIndex
      state.currentRow = rowIndex
    },

    duplicateExtraWithNewOption: {
      reducer: (
        state,
        action: PayloadAction<{
          newExtraId: string
          newOptionId: string
          extraId: string
          categoryIndex: number
          itemIndex: number
          extraIndex: number
        }>
      ) => {
        const { extraId, newExtraId, newOptionId, itemIndex, categoryIndex, extraIndex } =
          action.payload

        const extra = state.extras.find((e) => e.id === extraId)

        const newExtra: TExtra = cloneDeep(extra!)
        newExtra.id = newExtraId

        newExtra.options.push({ id: newOptionId, name: '', price: '' })

        state.extras.push(newExtra)
        state.categories[categoryIndex].items[itemIndex].extras[extraIndex] = newExtraId
      },
      prepare: ({
        extraId,
        itemIndex,
        categoryIndex,
        extraIndex,
      }: {
        extraId: string
        categoryIndex: number
        itemIndex: number
        extraIndex: number
      }) => {
        const newExtraId = uuid()
        const newOptionId = uuid()

        return {
          payload: {
            newExtraId,
            newOptionId,
            extraId,
            itemIndex,
            categoryIndex,
            extraIndex,
          },
        }
      },
    },

    duplicateExtraAndDeleteOption: {
      reducer: (
        state,
        action: PayloadAction<{
          newExtraId: string
          extraId: string
          categoryIndex: number
          itemIndex: number
          extraIndex: number
          optionIndex: number
        }>
      ) => {
        const { extraId, newExtraId, itemIndex, categoryIndex, extraIndex, optionIndex } =
          action.payload

        const extra = state.extras.find((e) => e.id === extraId)

        const newExtra: TExtra = cloneDeep(extra!)
        newExtra.id = newExtraId
        newExtra.options.splice(optionIndex, 1)

        state.extras.push(newExtra)
        state.categories[categoryIndex].items[itemIndex].extras[extraIndex] = newExtraId
      },
      prepare: ({
        extraId,
        itemIndex,
        categoryIndex,
        extraIndex,
        optionIndex,
      }: {
        extraId: string
        categoryIndex: number
        itemIndex: number
        extraIndex: number
        optionIndex: number
      }) => {
        const newExtraId = uuid()

        return {
          payload: {
            newExtraId,
            extraId,
            itemIndex,
            categoryIndex,
            extraIndex,
            optionIndex,
          },
        }
      },
    },
    duplicateExtraAndUpdateData: {
      reducer: (
        state,
        action: PayloadAction<{
          newExtraId: string
          newOptionId: string
          extraId?: string
          categoryIndex?: number
          itemIndex?: number
          extraIndex?: number
          optionIndex?: number
          field: string
          value: string
        }>
      ) => {
        const {
          extraId,
          newExtraId,
          itemIndex,
          categoryIndex,
          extraIndex,
          optionIndex,
          newOptionId,
          field,
          value,
        } = action.payload

        const extra = state.extras.find((e) => e.id === extraId)
        const newExtra: TExtra = cloneDeep(extra!)
        newExtra.id = newExtraId

        if (isUndefined(optionIndex)) {
          newExtra[field as keyof Omit<TExtra, 'options' | 'id' | 'parent'>] = value
        } else {
          const option = state.extras[extraIndex!].options[optionIndex!]
          const newOption = { ...option, id: newOptionId }
          newOption[field as 'name' | 'price'] = value
          newExtra.options[optionIndex] = { ...newOption }
        }

        state.extras.push(newExtra)
        state.categories[categoryIndex!].items[itemIndex!].extras[extraIndex!] = newExtraId
      },
      prepare: ({
        extraId,
        itemIndex,
        categoryIndex,
        extraIndex,
        optionIndex,
        field,
        value,
      }: {
        extraId?: string
        categoryIndex?: number
        itemIndex?: number
        extraIndex?: number
        optionIndex?: number
        field: string
        value: string
      }) => {
        const newExtraId = uuid()
        const newOptionId = uuid()

        return {
          payload: {
            newExtraId,
            newOptionId,
            extraId,
            itemIndex,
            categoryIndex,
            extraIndex,
            optionIndex,
            field,
            value,
          },
        }
      },
    },
    selectExtra: (
      state,
      action: PayloadAction<{
        extraId?: string
        categoryIndex?: number
        itemIndex?: number
        extraIndex?: number
      }>
    ) => {
      const { extraId, itemIndex, categoryIndex, extraIndex } = action.payload

      state.categories[categoryIndex!].items[itemIndex!].extras[extraIndex!] = extraId as string
    },

    convertRow: {
      reducer: (state, action: PayloadAction<{ destination: TRow; id: string }>) => {
        const flattenedData: TFlattenedData = flattenedDataSelector({
          //@ts-ignore
          menuState: {
            present: state,
          },
        })

        const { destination, id } = action.payload

        const row = flattenedData[state.currentRow]

        if (destination === 'item') {
          if (row.type !== 'category') {
            alert('Only categories can be converted to items')
            return
          }
          const preceding = state.categories.slice(0, row.categoryIndex) ?? []

          if (preceding.length === 0) {
            alert('Can not convert to item as there is no preceding category')
            return
          }

          const convertedRow = state.categories[row.categoryIndex!]

          if (convertedRow.items.length > 0) {
            alert('Can not convert to item as category has nested items')
            return
          }

          state.categories[row.categoryIndex! - 1].items.push({
            id,
            name: convertedRow?.name ?? '',
            description: convertedRow?.description ?? '',
            price: '',
            extras: [],
          })

          menuSlice.caseReducers.deleteRow(state, {
            ...action,
            payload: { type: 'category', categoryIndex: row.categoryIndex },
          })
        }

        if (destination === 'category') {
          if (row.type !== 'item') {
            alert('Only items can be converted to categories')
            return
          }

          const convertedRow = state.categories[row.categoryIndex!].items[row.itemIndex!]

          if (convertedRow.extras.length > 0) {
            alert('Can not convert to category as item has nested extras')
            return
          }

          menuSlice.caseReducers.deleteRow(state, {
            ...action,
            payload: {
              type: 'item',
              categoryIndex: row.categoryIndex,
              itemIndex: row.itemIndex,
            },
          })

          state.categories.splice(row.categoryIndex! + 1, 0, {
            id,
            name: convertedRow.name,
            description: convertedRow.description,
            items: [],
          })
        }

        if (destination === 'extra') {
          if (row.type !== 'item') {
            alert('Only items can be converted to extras')
            return
          }
          const preceding = state.categories[row.categoryIndex!].items.slice(0, row.itemIndex) ?? []

          if (preceding.length === 0) {
            alert('Can not convert to extra as there is no preceding item')
            return
          }

          const convertedRow = state.categories[row.categoryIndex!].items[row.itemIndex!]

          if (convertedRow.extras.length > 0) {
            alert('Can not convert to extra as item has nested extras')
            return
          }

          state.extras.push({
            id,
            name: convertedRow.name,
            min: '',
            max: '',
            free: '',
            options: [],
          })

          state.categories[row.categoryIndex!].items[row.itemIndex! - 1].extras.push(id)

          menuSlice.caseReducers.deleteRow(state, {
            ...action,
            payload: {
              type: 'item',
              categoryIndex: row.categoryIndex,
              itemIndex: row.itemIndex,
            },
          })
        }
      },
      prepare: ({ destination }: { destination: TRow }) => {
        const id = uuid()

        return {
          payload: { destination, id },
        }
      },
    },
  },
})

export const {
  addRow,
  deleteRow,
  resetMenu,
  updateRow,
  navigate,
  fixCase,
  handleOnDragEnd,
  makeRowActive,
  duplicateExtraWithNewOption,
  duplicateExtraAndDeleteOption,
  duplicateExtraAndUpdateData,
  selectExtra,
  convertRow,
  setMenu,
  setData,
  multiplyAllCells,
  multiplyCell,
} = menuSlice.actions

export const saveToFirebaseMiddleware: Middleware<{}, RootState> =
  (store) => (next) => async (action) => {
    const { type } = action

    if (
      type === navigate.toString() ||
      type === makeRowActive.toString() ||
      type === setMenu.toString() ||
      type === setData.toString() ||
      type.split('/')[0] !== 'menuSlice'
    ) {
      return next(action)
    }

    const result = next(action)

    const menu = store.getState().ocrState.activeMenu

    if (menu?.hash) {
      try {
        await axios.post(`/api/save-menu?id=${menu.hash}`, {
          menu: {
            ...menu,
            rows: {
              categories: store.getState().menuState.present.categories,
              extras: store.getState().menuState.present.extras,
            },
            version: 2,
          },
        })
      } catch (error) {
        logger.error(`Error making axios call to save-menu`, error)
      }
    }

    return result
  }

export default menuSlice.reducer
