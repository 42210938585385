import { createContext, FC } from 'react'

const FirebaseContext = createContext({})
FirebaseContext.displayName = 'Firebase'

// eslint-disable-next-line @typescript-eslint/no-explicit-any

// eslint-disable-next-line @typescript-eslint/ban-types
export const withFirebase = (Component: FC<any>) => {
  const ComponentWithFirebase = (props: Record<string, unknown>) => (
    <FirebaseContext.Consumer>
      {(firebase) => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
  )
  ComponentWithFirebase.displayName = `withFirebase(${Component.displayName || Component.name})`
  return ComponentWithFirebase
}

export default FirebaseContext
