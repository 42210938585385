import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { flatten, flow, map, sortBy } from 'lodash/fp'

import { IMenu, IMenuPage, IPolygon } from '../models/Menu'
import { TLoadMenu, TOcrState } from '../types'

const initialState: TOcrState = {
  imageUrls: {},
  imageSizes: {},
  menus: [],
  imageText: '',
  activeMenu: null,
  words: {},
  blocks: {},
  rotationAngle: 0,
  zoomLevel: 100,
  error: '',
  loading: false,
  insertionProposal: null,
  tableMode: true,
  annotationsVisible: false,
  wordMode: true,
  isEditing: false,
}

export const loadMenu = createAsyncThunk(
  'ocrSlice/loadMenu',
  async ({ menu, language }: TLoadMenu, thunkAPI) => {
    const imageUrls: Record<string, any> = {}
    const imageSizes: Record<string, any> = {}
    const blocks: Record<string, any> = {}
    const words: Record<string, any> = {}
    let imageText = ''

    const analyzeImage = async (path: string, hash: string) => {
      const response = await axios.post('/api/analyze-image', {
        storagePath: path,
        language,
      })
      await axios.get(`/api/load-menu?id=${hash}`)

      if (!response || !response.data || !response.data.result[0]) return

      imageText += response?.data?.result?.[0]?.responses?.[0]?.fullTextAnnotation?.text ?? ''
      words[path] = response?.data?.result?.[0]?.responses?.[0]?.textAnnotations
        .slice(1)
        .map((x: any) => ({
          text: x.description,
          coords: (
            x.boundingPoly.vertices as Array<{
              x: number
              y: number
            }>
          )
            .map((v) => `${v.x},${v.y}`, '')
            .join(' '),
        }))
      const page = response.data.result[0]?.responses[0].fullTextAnnotation?.pages[0]
      if (!page) return

      imageSizes[path] = { width: page.width, height: page.height }
      blocks[path] = flatten(
        page.blocks.map((block: any) =>
          block.paragraphs.map((para: any) => {
            const words = para.words
              .map((word: any) => word.symbols.map((s: any) => s.text).join(''))
              .join(' ')
            return {
              text: words,
              coords: (
                para.boundingBox.vertices as Array<{
                  x: number
                  y: number
                }>
              )
                .map((v) => `${v.x},${v.y}`, '')
                .join(' '),
            }
          })
        )
      )
    }

    if (menu.convertedImages) {
      await Promise.all(
        flow(
          sortBy((a: IMenuPage) => a.page),
          map(async (e: IMenuPage) => {
            imageUrls[e.storagePath] = e.publicUrl
            await analyzeImage(e.storagePath, menu.hash)
          })
        )(menu.convertedImages)
      )
    } else {
      imageUrls[menu.storagePath as string] = menu.publicUrl
      await analyzeImage(menu.storagePath as string, menu.hash)
    }

    return {
      imageUrls,
      imageSizes,
      blocks,
      words,
      imageText,
      activeMenu: menu,
    }
  }
)

const ocrSlice = createSlice({
  name: 'ocrSlice',
  initialState,
  reducers: {
    selectWord: (state, action: PayloadAction<IPolygon | null>) => {
      state.insertionProposal = action.payload
    },

    rotateLeft: (state) => {
      state.rotationAngle -= 90
    },

    rotateRight: (state) => {
      state.rotationAngle += 90
    },

    zoomIn: (state) => {
      state.zoomLevel += 10
    },

    zoomOut: (state) => {
      state.zoomLevel -= 10
    },

    setTableMode: (state, action: PayloadAction<boolean>) => {
      state.tableMode = action.payload
    },

    setAnnotationVisible: (state, action: PayloadAction<boolean>) => {
      state.annotationsVisible = action.payload
    },

    setWordMode: (state, action: PayloadAction<boolean>) => {
      state.wordMode = action.payload
    },

    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadMenu.fulfilled,
      (
        state,
        action: PayloadAction<
          Pick<
            TOcrState,
            'imageUrls' | 'imageSizes' | 'blocks' | 'words' | 'imageText' | 'activeMenu'
          >
        >
      ) => {
        const { blocks, imageSizes, imageText, imageUrls, words, activeMenu } = action.payload

        return {
          ...state,
          blocks,
          imageSizes,
          imageText,
          imageUrls,
          words,
          loading: false,
          menus: [...state.menus, activeMenu] as IMenu[],
          activeMenu,
        }
      }
    )

    builder.addCase(loadMenu.rejected, (state, action) => {
      state.error = action.error.message ?? ''
      state.loading = false
    })

    builder.addCase(loadMenu.pending, (state) => {
      state.loading = true
    })
  },
})

export const {
  rotateLeft,
  rotateRight,
  selectWord,
  zoomIn,
  zoomOut,
  setAnnotationVisible,
  setTableMode,
  setWordMode,
  setIsEditing,
} = ocrSlice.actions

export default ocrSlice.reducer
