import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'

import * as firebase from 'firebase/app'

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTHDOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from '../config/env'

const config = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
}

const collectionName = 'menus'
interface IFirebase {
  db: firebase.firestore.Firestore
  analytics: firebase.analytics.Analytics
  docRef: (doc: string) => any
  getAnalytics: () => firebase.analytics.Analytics
  auth: firebase.auth.Auth
  googleAuthProvider: firebase.auth.GoogleAuthProvider
}

class Firebase implements IFirebase {
  db: firebase.firestore.Firestore
  analytics: firebase.analytics.Analytics
  auth: firebase.auth.Auth
  googleAuthProvider: firebase.auth.GoogleAuthProvider

  constructor() {
    !firebase.apps.length ? firebase.initializeApp(config) : firebase.app()
    this.db = firebase.firestore()
    if (typeof window !== 'undefined') {
      this.analytics = firebase.analytics()
      this.auth = firebase.auth()
      this.googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    }
  }

  docRef = (doc: string) => this.db.collection(collectionName).doc(doc)
  getAnalytics = () => this.analytics
  getGoogleAuthProvider = () => this.googleAuthProvider
}

export default new Firebase()
