import { LoadingModal } from '@invisible/ui/loading-modal'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'

import SignInPage from './SignInPage'

export const withAuth = (InnerComponent: NextPage) => {
  const Page: NextPage = (props) => {
    const { asPath } = useRouter()

    const { data: session, status } = useSession()
    const loading = status === 'loading'

    if (loading) return <LoadingModal text='Signing you in...' />
    if (!session?.user) {
      return <SignInPage redirectTo={asPath} />
    }
    return <InnerComponent {...props} />
  }

  return Page
}
