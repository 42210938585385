import '../../../global.css'
import '../styles/index.css'

import { SessionProvider } from '@invisible/authentication/client'
import { ErrorBoundary } from '@invisible/common/error-boundary'
import { ThemeProvider } from '@invisible/ui/themes'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import type { Session } from 'next-auth'
import { Provider as ReduxProvider } from 'react-redux'

import FirebaseContext from '../components/Firebase/FirebaseContext'
import Firebase from '../lib/firebase'
import store from '../state/store'

function Visionator({ Component, pageProps }: AppProps<{ session?: Session }>) {
  return (
    <SessionProvider session={pageProps?.session}>
      <ErrorBoundary>
        <FirebaseContext.Provider value={Firebase}>
          <ThemeProvider>
            <ReduxProvider store={store}>
              <Head>
                <title>Invisionator</title>
              </Head>
              <Component {...pageProps} />
            </ReduxProvider>
          </ThemeProvider>
        </FirebaseContext.Provider>
      </ErrorBoundary>
    </SessionProvider>
  )
}

export default Visionator
