import { combineReducers, configureStore } from '@reduxjs/toolkit'
import undoable, { excludeAction } from 'redux-undo'

import genericModeReducer, {
  makeCellActive,
  navigate as genericModeNavigate,
  saveToFirebaseMiddleware as genericModeSaveToFirebaseMiddleware,
} from './genericModeSlice'
import menuReducer, { makeRowActive, navigate, saveToFirebaseMiddleware } from './menuSlice'
import ocrReducer from './ocrSlice'

const rootReducer = combineReducers({
  menuState: undoable(menuReducer, {
    limit: 20,
    filter: excludeAction([navigate.toString(), makeRowActive.toString()]),
  }),
  genericState: undoable(genericModeReducer, {
    limit: 20,
    filter: excludeAction([genericModeNavigate.toString(), makeCellActive.toString()]),
  }),
  ocrState: ocrReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(saveToFirebaseMiddleware, genericModeSaveToFirebaseMiddleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

export default store
